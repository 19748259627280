/** 3*3 */
<template>
  <div class="split-screen-icon__three" :style="`width:${width}px;height:${height}px;`">
    <div class="top" :style="`height:${height / 3}px;`">
      <div class="list1"></div>
      <div class="list2"></div>
      <div class="list3"></div>
    </div>
    <div class="center" :style="`height:${height / 3}px;`">
      <div class="list4"></div>
      <div class="list5"></div>
      <div class="list6"></div>
    </div>
    <div class="bottom" :style="`height:${height / 3}px;`">
      <div class="list7"></div>
      <div class="list8"></div>
      <div class="list9"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  }
};
</script>

<style scoped lang="stylus">
.split-screen-icon__three {
    display: flex;
    flex-direction:column;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    .top {
      display: flex;
      flex-direction:row;
      width: 100%;
    //   background: red;
      flex:3;
      .list1 {
        flex:1;
        border-bottom:1px solid #ffffff;
        border-right:1px solid #ffffff;
        box-sizing: border-box;
      }
      .list2 {
        flex:1;
        border-bottom:1px solid #ffffff;
        border-right:1px solid #ffffff;
        box-sizing: border-box;
      }
      .list3 {
        flex:1;
        border-bottom:1px solid #ffffff;
        box-sizing: border-box;
      }
    }
    .center {
      display: flex;
      flex-direction:row;
      width: 100%;
    //   background: red;
      flex:3;
      .list4 {
        flex:1;
        border-bottom:1px solid #ffffff;
        border-right:1px solid #ffffff;
        box-sizing: border-box;
      }
      .list5 {
        flex:1;
        border-bottom:1px solid #ffffff;
        border-right:1px solid #ffffff;
        box-sizing: border-box;
      }
      .list6 {
        flex:1;
        border-bottom:1px solid #ffffff;
        box-sizing: border-box;
      }
    }
    .bottom {
      display: flex;
      flex-direction:row;
      width: 100%;
    //   background: red;
      .list7 {
        flex:1;
        border-right:1px solid #ffffff;
        box-sizing: border-box;
      }
      .list8 {
        flex:1;
        border-right:1px solid #ffffff;
        box-sizing: border-box;
      }
      .list9 {
        flex:1;
        box-sizing: border-box;
      }
    }
}
</style>
