/** 1+5 */
<template>
  <div class="split-screen-icon__five" :style="`width:${width}px;height:${height}px;`">
      <div class="top">
          <div class="left"></div>
          <div class="right">
              <div class="top"></div>
              <div class="bottom"></div>
          </div>
      </div>
      <div class="bottom">
          <div class="left"></div>
          <div class="center"></div>
          <div class="right"></div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  },
};
</script>

<style scoped lang="stylus">
.split-screen-icon__five {
    border: 1px solid #ffffff;
    display:flex;
    flex-direction:column;
    box-sizing: border-box;
    flex:10;
    .top {
      flex:7
      display:flex;
      flex-direction:row;
      .left {
        // background:red;
        flex:4.75;
        border-right:1px solid #ffffff;
        border-bottom:1px solid #ffffff;
      }
      .right {
          flex:2.25;
          display:flex;
          flex-direction:column;
          .top {
            flex:1.125;
            // background:blue;
            border-bottom:1px solid #ffffff;
          }
          .bottom {
            flex:1.125;
            // background:green;
            border-bottom:1px solid #ffffff;
          }
      }
    }
    .bottom {
      flex:3
      display:flex;
      flex-direction:row;
      .left {
        flex:1;
        // background:orange;
        border-right:1px solid #ffffff;
      }
      .center {
        flex:1;
        // background:#CCFFFF;
        border-right:1px solid #ffffff;
      }
      .right {
        flex:1;
        // background:#FF6666;
      }
    }
}
</style>
