/** 1*1 */
<template>
  <div class="layout__one">
    <div
      class="one"
      :class="{ one__active: activeIndex == 1 }"
      @click="handleClick(1)"
    >
      <video-flv :videoType="videoType" :httpFlv="httpFlvUrl" ref="flvPlayer" @errVideo="errVideo" v-if="isShowVideo">
      </video-flv>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Md5Handle from "js-md5";
import { getUrl } from "../helper";
import { nvrMediaUrl2, username, secretKey} from "@/common/js/videoConfig";
import * as utils from "@/common/js/utils";
import VideoFlv from "../player/videoFlv";
export default {
  components: {
    VideoFlv,
  },
  props: {
    videoType: {
      type: Number,
      default: 1
    },
    layoutData1: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 22,
    },
    height: {
      type: Number,
      default: 22,
    },
  },
  data() {
    return {
      activeIndex: 1,
      httpFlvUrl: "",
      isShowVideo: true,
    };
  },
  watch: {
    layoutData1: {
      handler(val) {
        // console.log(val,"layoutData1layoutData1layoutData1layoutData1")
        if (this.$refs.flvPlayer) {
          this.$refs.flvPlayer.flv_destroy();
        }
        this.httpFlvUrl = "";
        // console.log('777777777777777')
        // console.log(val)
        if (val.httpFlvUrl1 && val.httpFlvUrl1 != "") {
          this.isShowVideo = true;
          this.$nextTick(() => {
            this.httpFlvUrl = val.httpFlvUrl1;
          });
        } else {
          // console.log(this.$refs);
          if (this.$refs.flvPlayer) {
            this.$refs.flvPlayer.flv_destroy();
          }
          this.isShowVideo = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    errVideo(item,httpFlv){
      this.isShowVideo = false;
      console.log('出错了',item,)
      console.log('出错了',httpFlv,)
      this.getVideoUrl(httpFlv,item)
    },
    getVideoUrl(httpFlv,item){
        let params = {
          "userName": username,
          "stream_web": this.GetRequest(httpFlv).stream
        }
      let authentication = Md5Handle(username + secretKey);
      this.$axios({
        method: "post",
        url: nvrMediaUrl2,
        data: params,
        timeout: 50000,
        headers: {
          "content-type": "application/json",
          authentication: authentication, // authentication
        },
      }).then((res) => {
        console.log(res)
        this.isShowVideo = true;
        this.$refs.flvPlayer.flv(res.httpUrl)
      })
    },
    GetRequest(urlStr) {
       if (typeof urlStr == "undefined") {
           var url = decodeURI(location.search); //获取url中"?"符后的字符串
       } else {
           var url = "?" + urlStr.split("?")[1];
       }
       let theRequest = new Object();
       if (url.indexOf("?") != -1) {
           let str = url.substr(1);
           let strs = str.split("&");
           for (var i = 0; i < strs.length; i++) {
               theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
           }
       }
       return theRequest;
    },
    allFlvDestroy() {
      if (this.$refs.flvPlayer) {
        this.$refs.flvPlayer.flv_destroy();
      }
      this.isShowVideo = false;
    },
    handleClick(index) {
      if (this.activeIndex == index) {
        return;
        this.activeIndex = 0;
      } else {
        this.activeIndex = index;
      }
      // this.isActive = !this.isActive;
    },
    activeHasVideo() {
      return this.isShowVideo;
    },
  },
};
</script>

<style scoped lang="stylus">
.layout__one {
  box-sizing: border-box;
  // background:#000;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  // padding: 2px 3px 4px 1px;
  padding: 4px 4px 4px 4px;

  .one {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 100%, 0.1);
    // position relative;
  }

  .one__active {
    border: 1px solid #FF9900;
    transition: all linear 0.3s;
  }
}
</style>
