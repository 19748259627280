/** 2+6 */
<template>
  <div class="layout__seven">
    <div class="left">
      <div class="top" :class="{ seven__active: activeIndex == 1 }" @click="handleClick(1)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl1"
          ref="flvPlayer1"
          videoId="videoId1"
          v-if="isShowVideo1"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
      <div class="bottom" :class="{ seven__active: activeIndex == 2 }" @click="handleClick(2)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl2"
          ref="flvPlayer2"
          videoId="videoId2"
          v-if="isShowVideo2"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
    </div>
    <div class="center">
      <div class="top" :class="{ seven__active: activeIndex == 3 }" @click="handleClick(3)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl3"
          ref="flvPlayer3"
          videoId="videoId3"
          v-if="isShowVideo3"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
      <div class="center" :class="{ seven__active: activeIndex == 4 }" @click="handleClick(4)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl4"
          ref="flvPlayer4"
          videoId="videoId4"
          v-if="isShowVideo4"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
      <div class="bottom" :class="{ seven__active: activeIndex == 5 }" @click="handleClick(5)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl5"
          ref="flvPlayer5"
          videoId="videoId5"
          v-if="isShowVideo5"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
    </div>
    <div class="right">
      <div class="top" :class="{ seven__active: activeIndex == 6 }" @click="handleClick(6)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl6"
          ref="flvPlayer6"
          videoId="videoId6"
          v-if="isShowVideo6"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
      <div class="center" :class="{ seven__active: activeIndex == 7 }" @click="handleClick(7)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl7"
          ref="flvPlayer7"
          videoId="videoId7"
          v-if="isShowVideo7"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
      <div class="bottom" :class="{ seven__active: activeIndex == 8 }" @click="handleClick(8)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl8"
          ref="flvPlayer8"
          videoId="videoId8"
          v-if="isShowVideo8"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Md5Handle from "js-md5";
import VideoFlv from "../player/videoFlv";
import { nvrMediaUrl2, username, secretKey} from "@/common/js/videoConfig";
export default {
  components: {
    VideoFlv
  },
  props: {
    videoType: {
      type: Number,
      default: 1
    },
    layoutData7: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  },
  data() {
    return {
      activeIndex: 1,
      isShowVideo1: false,
      isShowVideo2: false,
      isShowVideo3: false,
      isShowVideo4: false,
      isShowVideo5: false,
      isShowVideo6: false,
      isShowVideo7: false,
      isShowVideo8: false,
      httpFlvUrl1: "",
      httpFlvUrl2: "",
      httpFlvUrl3: "",
      httpFlvUrl4: "",
      httpFlvUrl5: "",
      httpFlvUrl6: "",
      httpFlvUrl7: "",
      httpFlvUrl8: ""
    };
  },
  watch: {
    videoType(val) {
      this.allFlvDestroy()
    },
    layoutData7: {
      handler(val) {
        console.log(val);
        let data_ = JSON.parse(JSON.stringify(val));
        if (JSON.stringify(data_) == "{}") {
          this.allFlvDestroy();
        } else {
          this.createFlv(data_);
        }
      },
      deep: true
    }
  },
  methods: {
    errVideo(item,httpFlv){
      console.log('出错了',item,)
      console.log('出错了',httpFlv,)
      if(item == 'videoId1'){
        this.isShowVideo1 = false;
      }else if(item == 'videoId2'){
        this.isShowVideo2 = false;
      }else if(item == 'videoId3'){
        this.isShowVideo3 = false;
      }else if(item == 'videoId4'){
        this.isShowVideo4 = false;
      }else if(item == 'videoId5'){
        this.isShowVideo5 = false;
      }else if(item == 'videoId6'){
        this.isShowVideo6 = false;
      }else if(item == 'videoId7'){
        this.isShowVideo7 = false;
      }else if(item == 'videoId8'){
        this.isShowVideo8 = false;
      }
      this.getVideoUrl(httpFlv,item)
    },
    getVideoUrl(httpFlv,item){
        let params = {
          "userName": username,
          "stream_web": this.GetRequest(httpFlv).stream
        }
      let authentication = Md5Handle(username + secretKey);
      this.$axios({
        method: "post",
        url: nvrMediaUrl2,
        data: params,
        timeout: 50000,
        headers: {
          "content-type": "application/json",
          authentication: authentication, // authentication
        },
      }).then((res) => {
        console.log(res)
        if(item == 'videoId1'){
          this.isShowVideo1 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer1.flv(res.httpUrl)
          })
        }else if(item == 'videoId2'){
          this.isShowVideo2 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer2.flv(res.httpUrl)
          })
        }else if(item == 'videoId3'){
          this.isShowVideo3 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer3.flv(res.httpUrl)
          })
        }else if(item == 'videoId4'){
          this.isShowVideo4 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer4.flv(res.httpUrl)
          })
        }else if(item == 'videoId5'){
          this.isShowVideo5 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer5.flv(res.httpUrl)
          })
        }else if(item == 'videoId6'){
          this.isShowVideo6 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer6.flv(res.httpUrl)
          })
        }else if(item == 'videoId7'){
          this.isShowVideo7 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer7.flv(res.httpUrl)
          })
        }else if(item == 'videoId8'){
          this.isShowVideo8 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer8.flv(res.httpUrl)
          })
        }
      })
    },
    GetRequest(urlStr) {
       if (typeof urlStr == "undefined") {
           var url = decodeURI(location.search); //获取url中"?"符后的字符串
       } else {
           var url = "?" + urlStr.split("?")[1];
       }
       let theRequest = new Object();
       if (url.indexOf("?") != -1) {
           let str = url.substr(1);
           let strs = str.split("&");
           for (var i = 0; i < strs.length; i++) {
               theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
           }
       }
       return theRequest;
    },
    activeHasVideo() {
      return this[`isShowVideo${this.activeIndex}`];
    },
    createFlv(data) {
      for (let i = 1; i < 8; i++) {
        if (data[`httpFlvUrl${i}`] && data[`httpFlvUrl${i}`] != "") {
          //   this.isShowVideo = true;
          if (!this[`isShowVideo${i}`]) {
            console.log(this[`isShowVideo${i}`], `httpFlvUrl${i}`);
            this[`isShowVideo${i}`] = true;
            this.$nextTick(() => {
              this[`httpFlvUrl${i}`] = data[`httpFlvUrl${i}`];
            });
          } else if (this.activeIndex == i) {
            this[`httpFlvUrl${i}`] = "";
            if (this.$refs[`flvPlayer${i}`]) {
              this.$refs[`flvPlayer${i}`].flv_destroy();
            }
            this.$nextTick(() => {
              this[`httpFlvUrl${i}`] = data[`httpFlvUrl${i}`];
            });
          }
        } else {
          if (this[`isShowVideo${i}`]) {
            this[`httpFlvUrl${i}`] = "";
            if (this.$refs[`flvPlayer${i}`]) {
              this.$refs[`flvPlayer${i}`].flv_destroy();
            }
            this[`isShowVideo${i}`] = false;
          }
        }
      }
    },
    allFlvDestroy() {
      for (let i = 1; i < 8; i++) {
        this[`httpFlvUrl${i}`] = "";
        if (this.$refs[`flvPlayer${i}`]) {
          this.$refs[`flvPlayer${i}`].flv_destroy();
        }
        this[`isShowVideo${i}`] = false;
      }
    },
    handleClick(index) {
      if (this.activeIndex == index) {
        return;
        this.activeIndex = 0;
      } else {
        this.activeIndex = index;
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.layout__seven {
    // border: 1px solid #ffffff;
    display:flex;
    flex-direction:row;
    box-sizing: border-box;
    flex:9;
    height:100%;
    padding:4px 4px 4px 4px;
    &:hover{
      cursor pointer;
    }
    .seven__active{
      border: 1px solid #FF9900;
      transition: all linear 0.3s;
      box-sizing: border-box;
    }
    .left {
        box-sizing: border-box;
        margin-right: 5px;
        flex:3;
        display:flex;
        flex-direction:column;
        .top {
          box-sizing: border-box;
          background: hsla(0,0%,100%,.1);
          flex:1.5;
          margin-bottom:2.5px;
        //   background:green;
          // border-right:1px solid #ffffff;
          // border-bottom:1px solid #ffffff;
        }
        .bottom {
          margin-top:2.5px;
          box-sizing: border-box;
          background: hsla(0,0%,100%,.1);
          flex:1.5;
        //   background:red;
          // border-right:1px solid #ffffff;
        }
    }
    .center {
        // margin-left: 2.5px;
        // margin-right:2.5px;
        flex:3;
        display:flex;
        flex-direction:column;
        box-sizing: border-box;
        .top {
          height: 30%;
          flex:1;
          box-sizing: border-box;
          margin-right:2.5px;
          margin-bottom: 2.5px;
          background: hsla(0,0%,100%,.1);
        //   background:orange;
          // border-right:1px solid #ffffff;
          // border-bottom:1px solid #ffffff;
        }
        .center {
          height: 30%;
          flex:1;
          margin-top:2.5px;
          box-sizing: border-box;
          margin-right:2.5px;
          background: hsla(0,0%,100%,.1);
        //   background:blue;
          // border-right:1px solid #ffffff;
          // border-bottom:1px solid #ffffff;
        }
        .bottom {
          height: 30%;
          box-sizing: border-box;
          margin-right:2.5px;
          background: hsla(0,0%,100%,.1);
          flex:1;
          margin-top:5px;
        //   background:yellow;
          // border-right:1px solid #ffffff;
        }

    }
    .right {
        margin-left: 2.5px;
        flex:3;
        display:flex;
        flex-direction:column;
        box-sizing: border-box;
        .top {
          height: 30%;
          flex:1;
          box-sizing: border-box;
          background: hsla(0,0%,100%,.1);
          margin-bottom:2.5px;
          // margin-bottom:5px;
        //   background:#666699;
          // border-bottom:1px solid #ffffff;
        }
        .center {
          height: 30%;
          flex:1;
          box-sizing: border-box;
          // margin-top:2.5px;
          background: hsla(0,0%,100%,.1);
          margin-top:2.5px;
        //   background:#66CCCC;
          // border-bottom:1px solid #ffffff;
        }
        .bottom {
          height: 30%;
          flex:1;
          box-sizing: border-box;
          background: hsla(0,0%,100%,.1);
        //   background:#99CC33;
          margin-top:5px;
        }
    }
}
</style>
