var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout__five" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        {
          staticClass: "left",
          class: { five__active: _vm.activeIndex == 1 },
          on: {
            click: function ($event) {
              return _vm.handleClick(1)
            },
          },
        },
        [
          _vm.isShowVideo1
            ? _c("video-flv", {
                ref: "flvPlayer1",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl1,
                  videoId: "videoId1",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          {
            staticClass: "top",
            class: { five__active: _vm.activeIndex == 2 },
            on: {
              click: function ($event) {
                return _vm.handleClick(2)
              },
            },
          },
          [
            _vm.isShowVideo2
              ? _c("video-flv", {
                  ref: "flvPlayer2",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: {
                    videoType: _vm.videoType,
                    httpFlv: _vm.httpFlvUrl2,
                    videoId: "videoId2",
                  },
                  on: { errVideo: _vm.errVideo },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "bottom",
            class: { five__active: _vm.activeIndex == 3 },
            on: {
              click: function ($event) {
                return _vm.handleClick(3)
              },
            },
          },
          [
            _vm.isShowVideo3
              ? _c("video-flv", {
                  ref: "flvPlayer3",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: {
                    videoType: _vm.videoType,
                    httpFlv: _vm.httpFlvUrl3,
                    videoId: "videoId3",
                  },
                  on: { errVideo: _vm.errVideo },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        {
          staticClass: "left",
          class: { five__active: _vm.activeIndex == 4 },
          on: {
            click: function ($event) {
              return _vm.handleClick(4)
            },
          },
        },
        [
          _vm.isShowVideo4
            ? _c("video-flv", {
                ref: "flvPlayer4",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl4,
                  videoId: "videoId4",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "center",
          class: { five__active: _vm.activeIndex == 5 },
          on: {
            click: function ($event) {
              return _vm.handleClick(5)
            },
          },
        },
        [
          _vm.isShowVideo5
            ? _c("video-flv", {
                ref: "flvPlayer5",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl5,
                  videoId: "videoId5",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "right",
          class: { five__active: _vm.activeIndex == 6 },
          on: {
            click: function ($event) {
              return _vm.handleClick(6)
            },
          },
        },
        [
          _vm.isShowVideo6
            ? _c("video-flv", {
                ref: "flvPlayer6",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl6,
                  videoId: "videoId6",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }