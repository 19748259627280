var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "split-screen-icon__three",
      style: `width:${_vm.width}px;height:${_vm.height}px;`,
    },
    [
      _c("div", { staticClass: "top", style: `height:${_vm.height / 3}px;` }, [
        _c("div", { staticClass: "list1" }),
        _c("div", { staticClass: "list2" }),
        _c("div", { staticClass: "list3" }),
      ]),
      _c(
        "div",
        { staticClass: "center", style: `height:${_vm.height / 3}px;` },
        [
          _c("div", { staticClass: "list4" }),
          _c("div", { staticClass: "list5" }),
          _c("div", { staticClass: "list6" }),
        ]
      ),
      _c(
        "div",
        { staticClass: "bottom", style: `height:${_vm.height / 3}px;` },
        [
          _c("div", { staticClass: "list7" }),
          _c("div", { staticClass: "list8" }),
          _c("div", { staticClass: "list9" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }