<template>
  <div class="video-monitor__wrapper" :class="{ display: dialogVisible }">
    <div class="bg">
      <div class="modal">
        <div class="title-wrapper" ref="title">
          <span class="title">{{ title }}</span>
          <div class="close-wrapper" @click="close">
            <img src="./img/close.png" />
          </div>
        </div>
        <div class="video-type-box">
          <ul class="video-line">
            <li
              v-for="(item, i) in videoTypeList"
              :key="i"
              v-show="isPlayBack ? true : item.type != 2"
              :class="videoType == item.type ? 'select' : ''"
              @click="handleSelectVideoType(item.type)"
            >
              {{ item.name }}
            </li>
          </ul>
          <div class="passageway-wrapper" v-show="isShowPassageway">
            <template v-for="(item, index) in passagewayList_">
              <div
                @click="handlePassagewayClick(item, index)"
                :key="index"
                class="passageway"
                :class="{ passageway__active: item.isActive }"
              >
                <p :title="item.monitorEquipmentChannelName">
                  {{ item.monitorEquipmentChannelName | textLength }}
                </p>
              </div>
            </template>
            <div v-show="isShowChange" class="change" @click="change">
              <!-- <img src="./img/change.png" /> -->
              <span>换一批</span>
            </div>
          </div>
        </div>
        <div :class="isShowPassageway ? 'video-wrapper' : 'video-wrapper1'" ref="videoLayout">
          <layout-index
            @item-click="layoutClick"
            :layout="layout"
            ref="layout"
            :videoType="videoType"
            :layoutData1="layoutData1"
            :layoutData2="layoutData2"
            :layoutData3="layoutData3"
            :layoutData4="layoutData4"
            :layoutData5="layoutData5"
            :layoutData6="layoutData6"
            :layoutData7="layoutData7"
            :loadingInstance="loadingInstance"
          ></layout-index>
        </div>
        <div class="footer-wrapper">
          <el-date-picker
            v-show="isPlayBack && videoType == 2"
            v-model="videoPlayBackDate"
            class="play-back-date"
            type="datetimerange"
            :clearable="false"
            :picker-options="pickerOptions"
            start-placeholder="回放开始日期"
            end-placeholder="回放结束日期"
            @change="handlePlayBackChange"
          >
          </el-date-picker>
          <el-tooltip class="item" effect="dark" content="1x1" placement="top">
            <div class="icon-one" @click="iconClick('1*1')">
              <icon-one></icon-one>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="2x2" placement="top">
            <div class="icon-two" @click="iconClick('2*2')">
              <icon-two></icon-two>
            </div>
          </el-tooltip>
          <!-- <el-tooltip class="item" effect="dark" content="3x3" placement="top">
            <div class="icon-three" @click="iconClick('3*3')"><icon-three></icon-three></div>
          </el-tooltip> -->
          <el-tooltip class="item" effect="dark" content="1+2" placement="top">
            <div class="icon-four" @click="iconClick('1+2')">
              <icon-four></icon-four>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="1+5" placement="top">
            <div class="icon-five" @click="iconClick('1+5')">
              <icon-five></icon-five>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="3+4" placement="top">
            <div class="icon-six" @click="iconClick('3+4')">
              <icon-six></icon-six>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="2+6" placement="top">
            <div class="icon-seven" @click="iconClick('2+6')">
              <icon-seven :width="23"></icon-seven>
            </div>
          </el-tooltip>
          <img src="./img/fullScreen.png" @click="fullScreenClick" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { fullScreen, exitFullscreen } from "./helper";
import IconOne from "./split-screen-icon/one";
import IconTwo from "./split-screen-icon/two";
import IconThree from "./split-screen-icon/three";
import IconFour from "./split-screen-icon/four";
import IconFive from "./split-screen-icon/five";
import IconSix from "./split-screen-icon/six";
import IconSeven from "./split-screen-icon/seven";
import LayoutIndex from "./layout/";
import { getUrl, getNVRDetail, getOutNVRDetail } from "./helper";
import { username } from "@/common/js/videoConfig";
import { getZeroOrLastDateTime, initUTCDate } from "@/common/js/utils";
export default {
  components: {
    IconOne,
    IconTwo,
    IconThree,
    IconFour,
    IconFive,
    IconSix,
    IconSeven,
    LayoutIndex,
  },
  props: {
    // 判断是否为路外
    isRoadOutside: {
      type: Boolean,
    },
    isShowPassageway: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    passagewayList: {
      type: Array,
      default: () => [],
    },
    isShowChange: {
      type: Boolean,
      default: true,
    },
    isPlayBack: {
      // 是否支持回放
      type: Boolean,
      default: false,
    },
  },
  watch: {
    passagewayList: {
      handler(val) {
        console.log("video_watch--->", val);
        if (val.length > 0) {
          this.passagewayList_ = JSON.parse(JSON.stringify(val));
          // this.handlePassagewayClick(this.passagewayList_[0], 0);
          // this.passagewayList_[0].isActive = true;
        } else {
          this.passagewayList_ = [];
        }
        console.log("list--->", this.passagewayList_);
        // this.passagewayList_.forEach((item, index) => {
        //   if (index > 0) {
        //     item.isActive = false;
        //   } else {
        //     item.isActive = true;
        //   }
        // });
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      layout: "1*1", // 1*1 2*2 3*3 1+2 1+5 3+4
      passagewayList_: [],
      dialogVisible: false,
      layoutData1: {},
      layoutData2: {},
      layoutData3: {},
      layoutData4: {},
      layoutData5: {},
      layoutData6: {},
      layoutData7: {},
      // passagewayList: ["通道1","通道2","通道3","通道4","通道5","通道6","通道7","通道8","通道9","通道10"]
      videoTypeList: [
        // 视频播放类型
        {
          type: 1,
          name: "预览",
        },
        {
          type: 2,
          name: "回放",
        },
      ],
      videoType: 1, // 选中的类型  1: 预览 flv, 2: 回放
      videoPlayBackDate: [], // 回放选择时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loadingInstance: "",
    };
  },
  methods: {
    handleCloseLoadding() {
      this.loadingInstance.close();
      this.loadingInstance = "";
    },
    handleSelectVideoType(type) {
      if (this.loadingInstance) {
        this.handleCloseLoadding();
      }
      if (type == this.videoType) return;
      if (type == 2) {
        this.getDate();
      }
      this.videoType = type;
      this.iconClick("1*1", true);
    },
    handlePlayBackChange(value) {
      if (!value || value.length == 0) {
        this.$alert("请选择回放日期范围！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        this.getDate();
      } else {
        // this.iconClick("1*1", true);
        let layoutIndex = 1;
        switch (this.layout) {
          case "2*2":
            layoutIndex = 2;
            break;
          case "3*3":
            layoutIndex = 3;
            break;
          case "1+2":
            layoutIndex = 4;
            break;
          case "1+5":
            layoutIndex = 5;
            break;
          case "3+4":
            layoutIndex = 6;
            break;
          case "2+6":
            layoutIndex = 7;
            break;
          default:
            break;
        }
        console.log(1);
        const berthIds =
          this[`layoutData${layoutIndex}`][`berthIds-${this.$refs.layout.getActiveIndex()}`] || "";
        console.log(berthIds);
        // if (!this.$refs.layout.activeHasVideo() || !berthIds) return;
        let passagewayItem = "",
          passagewayIndex = "";
        for (let i = 0; i < this.passagewayList_.length; i++) {
          if (this.passagewayList_[i].isActive) {
            passagewayItem = this.passagewayList_[i];
            passagewayIndex = i;
            break;
          }
        }
        if (passagewayItem) {
          console.log(passagewayItem, passagewayIndex);
          this.handlePassagewayClick(passagewayItem, passagewayIndex, true);
        }
      }
    },
    //获取时间的默认值
    getDate() {
      let start = getZeroOrLastDateTime("yyyy-MM-dd HH:mm:ss", -1, true);
      let end = getZeroOrLastDateTime("yyyy-MM-dd HH:mm:ss", 0, true);
      if (!this.videoPlayBackDate || this.videoPlayBackDate.length == 0) {
        this.videoPlayBackDate = [];
        this.videoPlayBackDate.push(start, end);
      }
      // console.log(this.videoPlayBackDate)
    },
    layoutClick() {},
    iconClick(layout, selectStatus = false) {
      // selectStatus = true 解决1 * 1 切换预览和回放不切换数据源问题
      // console.log(layout);
      if (layout == this.layout && !selectStatus) {
        return;
      }
      this.layoutData1 = {};
      this.layoutData2 = {};
      this.layoutData3 = {};
      this.layoutData4 = {};
      this.layoutData5 = {};
      this.layoutData6 = {};
      this.layoutData7 = {};
      // console.log(layout, "55555555555555555555");
      this.$nextTick(() => {
        this.layout = layout;
        this.init();
        this.$nextTick(() => {
          this.passagewayList_.forEach((item) => {
            item.isActive = false;
          });
          this.handlePassagewayClick(this.passagewayList_[0], 0);
        });
      });
    },
    clearData() {
      this.layoutData1 = {};
      this.layoutData2 = {};
      this.layoutData3 = {};
      this.layoutData4 = {};
      this.layoutData5 = {};
      this.layoutData6 = {};
      this.layoutData7 = {};
      this.$nextTick(() => {
        // this.layout = layout;
        this.init();
        this.$nextTick(() => {
          this.passagewayList_.forEach((item) => {
            item.isActive = false;
          });
          this.handlePassagewayClick(this.passagewayList_[0], 0);
        });
      });
      // console.log(layout, "55555555555555555555");
    },
    change() {
      this.$emit("change");
    },
    async handlePassagewayClick(item, index, isInit = false) {
      if (this.loadingInstance) {
        this.handleCloseLoadding();
      }
      if (this.layout == "1*1") {
        if (item.isActive && !isInit) {
          this.passagewayList_[index].isActive = false;
          this.$set(this.passagewayList_, index, this.passagewayList_[index]);
          this.layoutData1 = {};
        } else {
          // this.$nextTick(() => {
          //   console.log(document.querySelector(".layout__one"));
          // });
          this.passagewayList_.forEach(async (item, i) => {
            if (i == index) {
              // const layoutRef = this.$refs.layout;
              console.log(document.querySelector(".layout__one").offsetHeight);
              this.loadingInstance = this.$loading({
                target: document.querySelector(".layout__one"),
                spinner: "el-icon-loading",
                text: "加载中,请稍后...",
                background: "rgba(0, 0, 0, 0.6)",
                fullscreen: false,
              });
              this.passagewayList_[i].isActive = true;
              this.$set(this.passagewayList_, i, this.passagewayList_[i]);

              // 测试-----------------------------start
              // let layoutData1 = JSON.parse(
              //   JSON.stringify(this.passagewayList_[i])
              // );
              // if (this.videoType == 1) {
              //   // 预览实时监控
              //   layoutData1[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] =
              //     "http://121.89.203.100:9000/live?port=1935&app=myapp&stream=monitor_41e2889071b6962014b6ca132a26c6c8";
              // } else if (this.videoType == 2) {
              //   // 新增  时间逻辑
              //   layoutData1[`berthIds-${this.$refs.layout.getActiveIndex()}`] = layoutData1.berthIds || ''
              //   // 回放接收参数
              //     layoutData1[
              //       `httpFlvUrl${this.$refs.layout.getActiveIndex()}`
              //     ] = "http://vjs.zencdn.net/v/oceans.mp4";
              //     // 新增  时间逻辑

              // }
              // this.layoutData1 = layoutData1;
              // // console.log(this.layoutData1);
              // this.handleCloseLoadding()
              // 测试-----------------------------end
              let NVRDetail = {};
              if (this.isRoadOutside) {
                NVRDetail = await getOutNVRDetail(this.passagewayList_[index].monitorEquipmentId);
              } else {
                NVRDetail = await getNVRDetail(this.passagewayList_[index].monitorEquipmentId);
              }
              if (!NVRDetail.monitorEquipmentType) {
                NVRDetail.monitorEquipmentType = 1;
              }
              let result = {};
              if (this.videoType == 1) {
                // 预览视频
                result = await getUrl(
                  NVRDetail.videoEquipmentCode,
                  username,
                  NVRDetail.monitorEquipmentType == 1 ? "HK" : "DH",
                  NVRDetail.ipAddress,
                  NVRDetail.ipPort,
                  NVRDetail.account,
                  NVRDetail.password,
                  this.passagewayList_[index].monitorEquipmentChannelCode,
                  1,
                  "Channels",
                  "",
                  "",
                  NVRDetail.netType,
                  NVRDetail.gbChannelId,
                  "yes",
                  NVRDetail.gbEquipmentCode
                );
              } else if (this.videoType == 2) {
                // 回放
                let startTime = initUTCDate(this.videoPlayBackDate[0]),
                  endTime = initUTCDate(this.videoPlayBackDate[1]);
                console.log(this.videoPlayBackDate);
                result = await getUrl(
                  NVRDetail.videoEquipmentCode,
                  username,
                  NVRDetail.monitorEquipmentType == 1 ? "HK" : "DH",
                  NVRDetail.ipAddress,
                  NVRDetail.ipPort,
                  NVRDetail.account,
                  NVRDetail.password,
                  this.passagewayList_[index].monitorEquipmentChannelCode,
                  1,
                  "Tracks",
                  startTime, // "20211001T050310Z",
                  endTime, // "20211004T050510Z"
                  NVRDetail.netType,
                  NVRDetail.gbChannelId,
                  "yes",
                  NVRDetail.gbEquipmentCode
                );
              }

              if (result && result.code && result.code == 200) {
                this.loadingInstance.text = "加载中,请稍后...";
              } else if(result && result.code && result.code == -1){
                this.loadingInstance.text = result.msg ?? '超过播放路数上限';
                return;
              } else {
                this.loadingInstance.text = "加载失败";
                return;
              }

              // if (result.httpUrl) {
              let layoutData1 = JSON.parse(JSON.stringify(this.passagewayList_[i]));
              // if (this.videoType == 1) {
              // 预览实时监控
              let httpUrlUrl = result.httpUrl && result.httpUrl.split("live")[1];
              // if (window.location.protocol == "https:") {
              //   // httpUrlUrl = result.httpsUrl;
              //   layoutData1[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] =
              //   //  httpUrlUrl; // 商用需要放开注释
              //   "/live" + httpUrlUrl; // 商用需要放开注释
              // } else {
              //   layoutData1[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] =
              //   // result.httpUrl;
              //   "/live" + httpUrlUrl; // 商用需要放开注释
              // }
              if (window.location.protocol == "https:") {
                httpUrlUrl = result.httpsUrl;
                layoutData1[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] = httpUrlUrl; // 商用需要放开注释
              } else {
                layoutData1[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] = result.httpUrl;
                // "/live" + httpUrlUrl; // 商用需要放开注释
              }
              // 新增  时间逻辑
              layoutData1[`berthIds-${this.$refs.layout.getActiveIndex()}`] =
                layoutData1.berthIds || "";

              // layoutData1[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] =
              //   result.httpUrl; // 本地调试
              // } else if (this.videoType == 2) {
              // 回放接收参数
              // layoutData1[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] = 回放参数字段
              // }

              this.layoutData1 = layoutData1;
              // console.log(layoutData1);
              // }

              this.handleCloseLoadding();
            } else {
              this.passagewayList_[i].isActive = false;
              this.$set(this.passagewayList_, i, this.passagewayList_[i]);
              // this.layoutData1 = {};  // 增加回放功能，此行代码影响 单屏多个监控列表，无法显示问题
            }
          });
        }
      } else {
        let layoutIndex = 0;
        switch (this.layout) {
          case "2*2":
            layoutIndex = 2;
            break;
          case "3*3":
            layoutIndex = 3;
            break;
          case "1+2":
            layoutIndex = 4;
            break;
          case "1+5":
            layoutIndex = 5;
            break;
          case "3+4":
            layoutIndex = 6;
            break;
          case "2+6":
            layoutIndex = 7;
            break;
          default:
            break;
        }
        if (item.isActive && !isInit) {
          this.passagewayList_[index].isActive = false;
          this.$set(this.passagewayList_, index, this.passagewayList_[index]);
          this[`layoutData${layoutIndex}`][`${this.passagewayList_[index]._key}`] = "";
          // 回放时间选择 确认按钮逻辑
          this[`layoutData${layoutIndex}`][`${this.passagewayList_[index]._dateKey}`] = "";
        } else {
          if (this.$refs.layout.activeHasVideo() && !isInit) {
            this.$alert("请选择没有播放的分屏通道！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            const map = {
              "1*1": ".one__active",
              "2*2": ".two__active",
              "3*3": ".three__active",
              "1+2": ".four__active",
              "1+5": ".five__active",
              "3+4": ".six__active",
              "2+6": ".seven__active",
            };
            this.loadingInstance = this.$loading({
              target: document.querySelector(`${map[this.layout]}`),
              spinner: "el-icon-loading",
              text: "加载中,请稍后...",
              background: "rgba(0, 0, 0, 0.6)",
              fullscreen: false,
            });
            this.passagewayList_[index].isActive = true;
            this.$set(this.passagewayList_, index, this.passagewayList_[index]);

            //  -----------测试  start--------------

            // let layoutData = Object.assign(
            //   {},
            //   JSON.parse(JSON.stringify(this.passagewayList_[index])),
            //   this[`layoutData${layoutIndex}`]
            // );
            // if (this.videoType == 1) {
            //   // 预览监控
            //   layoutData[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] = 'http://121.89.203.100:9000/live?port=1935&app=myapp&stream=monitor_41e2889071b6962014b6ca132a26c6c8'
            // } else if (this.videoType == 2) {
            //   // 新增  时间逻辑
            //   this.passagewayList_[
            //     index
            //   ]._dateKey = `berthIds-${this.$refs.layout.getActiveIndex()}`;
            //   layoutData[`berthIds-${this.$refs.layout.getActiveIndex()}`] = this.passagewayList_[index].berthIds || ''
            //   // 回放
            //   layoutData[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] = 'http://vjs.zencdn.net/v/oceans.mp4';

            // }

            // this.passagewayList_[
            //   index
            // ]._key = `httpFlvUrl${this.$refs.layout.getActiveIndex()}`;
            // this[`layoutData${layoutIndex}`] = layoutData;
            // // console.log(this[`layoutData${layoutIndex}`])
            // this.handleCloseLoadding()

            //  -----------测试  end--------------
            let NVRDetail = {};
            if (this.isRoadOutside) {
              NVRDetail = await getOutNVRDetail(this.passagewayList_[index].monitorEquipmentId);
            } else {
              NVRDetail = await getNVRDetail(this.passagewayList_[index].monitorEquipmentId);
            }
            if (!NVRDetail.monitorEquipmentType) {
              NVRDetail.monitorEquipmentType = 1;
            }
            let result = {};
            if (this.videoType == 1) {
              // 预览视频
              result = await getUrl(
                NVRDetail.videoEquipmentCode,
                username,
                NVRDetail.monitorEquipmentType == 1 ? "HK" : "DH",
                NVRDetail.ipAddress,
                NVRDetail.ipPort,
                NVRDetail.account,
                NVRDetail.password,
                this.passagewayList_[index].monitorEquipmentChannelCode,
                1,
                "Channels",
                "",
                "",
                NVRDetail.netType,
                NVRDetail.gbChannelId,
                "yes",
                NVRDetail.gbEquipmentCode
              );
            } else if (this.videoType == 2) {
              // 回放
              let startTime = initUTCDate(this.videoPlayBackDate[0]),
                endTime = initUTCDate(this.videoPlayBackDate[1]);
              console.log(this.videoPlayBackDate);
              result = await getUrl(
                NVRDetail.videoEquipmentCode,
                username,
                NVRDetail.monitorEquipmentType == 1 ? "HK" : "DH",
                NVRDetail.ipAddress,
                NVRDetail.ipPort,
                NVRDetail.account,
                NVRDetail.password,
                this.passagewayList_[index].monitorEquipmentChannelCode,
                1,
                "Tracks",
                startTime, // "20211001T050310Z",
                endTime, // "20211004T050510Z"
                NVRDetail.netType,
                NVRDetail.gbChannelId,
                "yes",
                NVRDetail.gbEquipmentCode
              );
            }

            if (result && result.code && result.code == 200) {
              this.loadingInstance.text = "加载中,请稍后...";
            } else if(result && result.code && result.code == -1){
              this.loadingInstance.text = result.msg ?? '超过播放路数上限';
              return;
            } else {
              this.loadingInstance.text = "加载失败";
              return;
            }

            // if (result.httpsUrl) {
            let layoutData = Object.assign(
              {},
              JSON.parse(JSON.stringify(this.passagewayList_[index])),
              this[`layoutData${layoutIndex}`]
            );
            // if (this.videoType == 1) {  // 预览监控

            // 新增  时间逻辑
            this.passagewayList_[index]._dateKey = `berthIds-${this.$refs.layout.getActiveIndex()}`;
            layoutData[`berthIds-${this.$refs.layout.getActiveIndex()}`] =
              this.passagewayList_[index].berthIds || "";

            if (window.location.protocol == "https:") {
              let httpUrlUrl = result.httpsUrl.split("live")[1];
              layoutData[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] = result.httpsUrl;
              this[`layoutData${layoutIndex}`][`${this.passagewayList_[index]._key}`] = "";
            } else {
              layoutData[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] = result.httpUrl;
            }

            // } else if (this.videoType == 2) {
            // 回放
            // layoutData[`httpFlvUrl${this.$refs.layout.getActiveIndex()}`] = 回放字段;
            // }

            this.passagewayList_[index]._key = `httpFlvUrl${this.$refs.layout.getActiveIndex()}`;
            this[`layoutData${layoutIndex}`] = layoutData;
            // }

            this.handleCloseLoadding();
          }
        }
      }
    },
    fullScreenClick() {
      fullScreen(this.$refs.videoLayout);
    },
    open() {
      this.dialogVisible = true;
      this.appendBody();
      this.$nextTick(() => {
        this.passagewayList_.forEach((item) => {
          item.isActive = false;
        });
        this.handlePassagewayClick(this.passagewayList_[0], 0);
      });
    },
    close() {
      if (this.loadingInstance) {
        this.handleCloseLoadding();
      }
      this.dialogVisible = false;
      this.layoutData1 = {};
      this.layoutData2 = {};
      this.layoutData3 = {};
      this.layoutData4 = {};
      this.layoutData5 = {};
      this.layoutData6 = {};
      this.layoutData7 = {};
      this.init();
      this.layout = "1*1";
      this.$refs.layout.allFlvDestroyVideo();
      this.$emit("close");
      // this.removeEL();
    },
    init() {
      this.passagewayList_.forEach((item, index) => {
        if (index > 0) {
          item.isActive = false;
        } else {
          item.isActive = true;
        }
      });
      // this.layout = "1*1"
      // this.iconClick("1*1")
    },
    removeEL() {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        body.removeChild(this.$el);
      });
    },
    appendBody() {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        if (body.append) {
          body.append(this.$el);
        } else {
          body.appendChild(this.$el);
        }
      });
    },
  },
  filters: {
    textLength(val) {
      if (val == undefined) {
      } else {
        if (val.length > 5) {
          return val.slice(0, 5) + "...";
        }
        return val;
      }
    },
  },
  created() {
    if (this.isPlayBack) {
      this.getDate();
    }
  },
  // mounted() {
  //   this.appendBody();
  // }
};
</script>

<style lang="stylus" scoped>
.video-monitor__wrapper {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s;
  background-color: transparent;
  .modal {
    // position: relative;
    // transform: scale(0.8);
    // transition: all 0.3s;
    // min-width: 20%;
    // min-height: 20%;
    width: 750px;
    height: 540px;
    min-width: 750px;
    background: linear-gradient(181deg, #18347a 1%, #08143b 91%);
    border: 1px solid rgba(100, 173, 255, 0.5);
    border-radius: 4px;

    .title-wrapper {
	  background-color: #2a427e;
      border-radius: 4px 4px 0 0;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        color: white;
        font-size: 16px;
        font-weight: bold;
      }

      .close-wrapper {
        img {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 20px;
          height: 20px;
          padding: 10px;

          &:hover {
            background: hsla(0, 0%, 100%, 0.1);
            cursor: pointer;
            transition: all 0.3s;
          }
        }
      }
    }

    .passageway-wrapper {
      position: relative;
      display: flex;
      // align-items:center;
      // justify-content:center;
      flex-direction: row;
      // justify-content:space-between;
      padding-left: 10px;
      padding-right: 10px;
      align-items: center;
      height: 50px;

      // background:trans
      // color:#ffffff;
      .passageway {
        // padding: 8px;
        height: 35px;
        font-size: 12px;
        width: 100px;
        border-radius: 4px;
        background: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: white;
        color: #2a427e;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          width: 70px;
        }

        &:hover {
          cursor: pointer;
        }

        margin-right: 15px;
      }

      .passageway__active {
        background: #0076f5;
        color: white;
        color: #fff;
        transition: all 0.3s;
      }

      .change {
        position: absolute;
        right: 11px;
        top: 20px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        color: white;

        span {
          font-size: 15px;
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }

        &:hover {
          color: #0076FB;
          cursor: pointer;
        }
      }
    }

    .video-wrapper {
      position: relative;
      height: calc(100% - 170px);
      // padding:5px;
      // background color url('image') position repeat
    }

    .video-wrapper1 {
      position: relative;
      height: calc(100% - 120px);
    }

    .footer-wrapper {
      position: absolute;
      bottom: 0;
      height: 40px;
      width: 100%;
      border-radius: 0 0 4px 4px;

      .icon-one {
        position: absolute;
        bottom: 0px;
        right: 240px;
        /* width: 20px; */
        /* height: 20px; */
        padding-bottom: 9px;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 10px;

        &:hover {
          background: hsla(0, 0%, 100%, 0.1);
          cursor: pointer;
          transition: all 0.3s;
        }
      }

      .icon-two {
        position: absolute;
        bottom: 0px;
        right: 200px;
        /* width: 20px; */
        /* height: 20px; */
        padding-bottom: 9px;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 10px;

        &:hover {
          background: hsla(0, 0%, 100%, 0.1);
          cursor: pointer;
          transition: all 0.3s;
        }
      }

      .icon-three {
        position: absolute;
        bottom: 0px;
        right: 200px;
        /* width: 20px; */
        /* height: 20px; */
        padding-bottom: 9px;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 10px;

        &:hover {
          background: hsla(0, 0%, 100%, 0.1);
          cursor: pointer;
          transition: all 0.3s;
        }
      }

      .icon-four {
        position: absolute;
        bottom: 0px;
        right: 160px;
        /* width: 20px; */
        /* height: 20px; */
        padding-bottom: 9px;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 10px;

        &:hover {
          background: hsla(0, 0%, 100%, 0.1);
          cursor: pointer;
          transition: all 0.3s;
        }
      }

      .icon-five {
        position: absolute;
        bottom: 0px;
        right: 120px;
        /* width: 20px; */
        /* height: 20px; */
        padding-bottom: 9px;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 10px;

        &:hover {
          background: hsla(0, 0%, 100%, 0.1);
          cursor: pointer;
          transition: all 0.3s;
        }
      }

      .icon-six {
        position: absolute;
        bottom: 0px;
        right: 80px;
        /* width: 20px; */
        /* height: 20px; */
        padding-bottom: 9px;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 10px;

        &:hover {
          background: hsla(0, 0%, 100%, 0.1);
          cursor: pointer;
          transition: all 0.3s;
        }
      }

      .icon-seven {
        position: absolute;
        bottom: 0px;
        right: 40px;
        /* width: 20px; */
        /* height: 20px; */
        padding-bottom: 9px;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 10px;

        &:hover {
          background: hsla(0, 0%, 100%, 0.1);
          cursor: pointer;
          transition: all 0.3s;
        }
      }

      img {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 25px;
        height: 25px;
        padding: 7px;

        &:hover {
          background: hsla(0, 0%, 100%, 0.1);
          cursor: pointer;
          transition: all 0.3s;
        }
      }
    }
  }

  &.display {
    // z-index: 9999;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s;
    background-color: rgba(0, 0, 0, 0.8);

    .modal {
      transform: scale(1);
      transition: all 0.5s;
    }
  }

  .video-type-box {
    .video-line {
      display: flex;
      height: 40px;
      line-height: 40px;
      padding-left: 24px;

      li {
        font-size: 20px;
        margin-right: 10px;
        color: #fff;
        cursor: pointer;

        &.select {
          color: #2DB7F5;
        }
      }
    }
  }

  .play-back-date {
    margin-left: 10px;
    background-color: transparent;
    border: none;

    /deep/ {
      .el-range-input {
        background-color: transparent;
        color: #fff;
      }

      .el-range-separator {
        color: #fff !important;
      }
    }
  }
}
</style>
