import Md5Handle from "js-md5";
import axios from "axios";
import { MessageBox } from "element-ui";
import Vue from 'vue';
import { nvrMediaUrl1, nvrMediaUrl2, username, secretKey } from "@/common/js/videoConfig";
// 全屏的兼容
export const fullScreen = (ele) => {
  const func =
    ele.requestFullscreen ||
    ele.mozRequestFullScreen ||
    ele.webkitRequestFullscreen ||
    ele.msRequestFullscreen;
  func.call(ele);
};

// 退出全屏的兼容
export const exitFullscreen = () => {
  const func =
    document.exitFullScreen ||
    document.mozCancelFullScreen ||
    document.webkitExitFullscreen ||
    document.msExitFullscreen;
  func.call(document);
};

export const getUrl = (
  videoEquipmentCode = "",
  userName = username,
  devType = "HK",
  nvrIp = "114.253.13.134",
  nvrPort = "555",
  nvrUser = "admin",
  nvrPwd = "zhht2016",
  channelCode = "1",
  rtspType = 1,
  monitorType = "Channels", // "Channels"-实时、"Tracks"-历史
  startTime = "",
  endTime = "",
  netType = "",
  gbChannelId = "",
  isGb = "yes",
  gbDevId = ""
) => {
  let authentication = Md5Handle(username + secretKey);
  return new Promise((resolve, reject) => {
    var params = {};
    console.log(nvrIp, "dsfsdfdsf");
    if (nvrIp) {
      params = {
        userName,
        devType,
        nvrIp,
        nvrPort,
        nvrUser,
        nvrPwd,
        channelCode,
        rtspType,
        monitorType,
      };
    } else {
      params = {
        gbChannelId: channelCode,
        isGb,
        gbDevId: videoEquipmentCode,
        monitorType,
        userName,
      };
    }
    if (monitorType == "Tracks") {
      params.startTime = startTime;
      params.endTime = endTime;
    }
    axios({
      method: "post",
      url: nvrMediaUrl2,
      data: params,
      timeout: 50000,
      headers: {
        "content-type": "application/json",
        authentication: authentication, // authentication
      },
    }).then((res) => {
      if (res.code == -1) {
        // MessageBox.alert("视频流转换失败，请重试！", "提示", {
        //   confirmButtonText: "确定"
        // });
        resolve({});
      } else {
        resolve(res);
      }
    }).catch((e) => {
      reject(e);
    });
    /* let timer = setInterval(() => {
      console.log("nvrMediaUrl2--->", nvrMediaUrl2);
      axios({
        method: "post",
        url: nvrMediaUrl2,
        data: params,
        timeout: 50000,
        headers: {
          "content-type": "application/json",
          authentication: "284855fa4a5f4edb3d0612516165e481", // authentication
        },
      }).then((res) => {
        if (res.code == -1) {
          // MessageBox.alert("视频流转换失败，请重试！", "提示", {
          //   confirmButtonText: "确定"
          // });
          resolve({});
        } else {
          resolve(res);
        }
      }).catch((e) => {
        reject(e);
      });
    }, 1000 * 60 * 10) // 10分钟
    sessionStorage.setItem("flvOpenTimer", timer) */
  });
};

export const getNVRDetail = (monitorEquipmentId) => {
  return new Promise((resolve, reject) => {
    return axios({
      method: "get",
        url: `/acb/2.0/monitorEquipment/detail/${monitorEquipmentId}`
    })
      .then((res) => {
        if (res.state == 0) {
          resolve(res.value);
        } else {
          reject(res.desc);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getOutNVRDetail = (monitorEquipmentId) => {
  return new Promise((resolve, reject) => {
    return axios({
      method: "get",
        url: `/acb/2.0/bacb/monitorEquipment/detail/${monitorEquipmentId}`
    })
      .then((res) => {
        if (res.state == 0) {
          resolve(res.value);
        } else {
          reject(res.desc);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCommonNVRDetail = (monitorEquipmentId, parkId) => {
  return new Promise((resolve, reject) => {
    return axios({
      method: "get",
      url: '/acb/2.0/nvrMonitorRoute/detail',
      data: {
        monitorEquipmentId,
        parkId,
      },
    })
      .then((res) => {
        if (res.state == 0) {
          resolve(res.value);
        } else {
          reject(res.desc);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
