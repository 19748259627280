<template>
  <div
    class="videoFlv"
    v-loading="loading"
    element-loading-text="加载中,请稍后..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <slot></slot>
    <video
      :id="videoId"
      :disablePictureInPicture="true"
      controls
      style="width: 100%; height: 100%; object-fit: fill"
    ></video>
  </div>
</template>
<script>
/* eslint-disable */
import flvjs from "flv.js";
import FlvExtend from "./flvExtend";
// import { mapState } from 'vuex'
export default {
  props: {
    videoType: {
      // 视频播放类型  1：预览  2：回放
      type: Number,
      default: 1,
    },
    httpFlv: {
      type: String,
      default: "",
    },
    videoId: {
      type: String,
      default: "videoElement",
    },
  },
  data() {
    return {
      file: "",
      flvPlayer: null,
      loading: false,
      playSrc: "",
      playTimer: "",
      flvData: "",
      videoIdArrdata: [],
      flvPlayerData: [],
      videoIdArr: [
        "videoElement",
        "videoId1",
        "videoId2",
        "videoId3",
        "videoId4",
        "videoId5",
        "videoId6",
        "videoId7",
        "videoId8",
      ],
    };
  },
  computed: {
    getPlayViewFlag() {
      return this.$store.state.playViewFlag; //需要监听的数据
    },
  },
  methods: {
    // 对象转字符串（不丢失对象方法）
    objectToString(obj, fun) {
      // 返回一个将对象中的函数都转化为字符串的对象　不直接在原对象上面改
      const stringifyFunction = (obj) => {
        let newobj = JSON.parse(JSON.stringify(obj));
        for (let key in obj) {
          if (obj[key] instanceof Function) {
            newobj[key] = obj[key].toString().replace(/[\n\t]/g, "");
            continue;
          }
          if (obj[key] instanceof Object) {
            newobj[key] = stringifyFunction(obj[key]);
          }
        }
        return newobj;
      };
      // 用于替代JSON.stringify函数
      let _object = stringifyFunction(obj); // 将对象中的函数转为字符串
      return JSON.stringify(_object); // 将对象转为字符串
    },
    // 字符串转对象（不丢失对象方法）
    stringToObject(string) {
      const stringToFunction = (obj) => {
        // 将对象中的函数字符串转化为函数
        var regex = /^((functon\s)|.)([a-zA-Z_][a-zA-Z0-9_]*)\(.*\)\s\{.*\}/; //匹配函数字符串
        for (let key in obj) {
          if (obj[key] instanceof Object) {
            stringToFunction(obj[key]);
          } else {
            if (regex.test(obj[key])) {
              // 是一个函数
              try {
                let params = obj[key].substring(obj[key].indexOf("(") + 1, obj[key].indexOf(")"));
                let operation = obj[key].substring(obj[key].indexOf("{") + 1, obj[key].length - 1);
                obj[key] = new Function(params, operation);
              } catch (e) {
                console.log(e);
              }
            }
          }
        }
        return obj;
      };
      // 用于替代JSON.parse
      let obj = JSON.parse(string); //将字符串转为对象
      return stringToFunction(obj); // 将对象中的函数字符串转为函数
    },
    showLoading() {
      this.loading = true;
    },
    closeLoading() {
      this.loading = false;
    },
    flv_destroy() {
      if (this.flvPlayer) {
        this.flvPlayer.pause();
        this.flvPlayer.unload();
        this.flvPlayer.detachMediaElement();
        this.flvPlayer.destroy();
        this.flvPlayer = null;
        window.onfocus = null;
      }
    },
    // 点击抓拍截图
    snapshot() {
      let currentTime = this.$refs.videoPlayer.$refs.video.currentTime;
      setTimeout(() => {
        var v = this.$refs.videoPlayer.$el.children[0].children[0];
        v.autoplay = false;
        var canvas = document.getElementById("myCanvas");
        var scaleBy = 10;
        canvas.width = 280 * scaleBy;
        canvas.height = 160 * scaleBy;
        var ctx1 = canvas.getContext("2d");
        ctx1.scale(scaleBy, scaleBy);
        ctx1.drawImage(v, 0, 0, 280, 160);
        document.getElementById("imgShow").style.width = "100%";
        this.previewImg = canvas.toDataURL("image/jpeg");
        this.file = this.dataURLtoFile(this.previewImg, "imgName");
        this.$emit("fun", this.file);
      }, 500);
      this.$emit("input", this.videoStartTime - 0 + Math.floor(currentTime));
    },
    // 查看大图
    showBigImg(url) {
      if (url) {
        this.showBigImage(url);
      }
    },
    // 将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    flv(url) {
      let that = this;
      // 暂时处理
      // if (url.search("http") == -1) {
      //   url = `http://47.92.212.222:9000${url}`;
      // }

      // url = 'http://47.92.212.222:9000/live?port=1935&app=myapp&stream=monitor_438bf1d609ff5e9e888f3a8f6ca89116&uuid=RUAYKRX80000'
      // console.log(url, "urlllllll");
      if (flvjs.isSupported()) {
        let videoElement = document.getElementById(this.videoId);
        let type = "flv",
          isLive = true;
        if (this.videoType == 2) {
          // type = 'mp4';
          isLive = false;
        }
        /* this.flvPlayer = flvjs.createPlayer(
          {
            type: type,
            url: url,
            isLive: isLive,
            enableWorker: true,
            enableStashBuffer: false,
            stashInitialSize: 128
          },
          {
            fixAudioTimestampGap: false,
          }
        ); */
        const flv = new FlvExtend({
          element: videoElement, // *必传
          frameTracking: false, // 开启追帧设置
          updateOnStart: true, // 点击播放后更新视频
          updateOnFocus: true, // 获得焦点后更新视频
          reconnect: true, // 开启断流重连
          reconnectInterval: 5000, // 断流重连间隔
        });
        this.flvData = flv;
        this.flvPlayer = flv.init(
          {
            type: type,
            url: url,
            isLive: isLive,
            playFlag: true,
            videoId: that.videoId,
          },
          {
            enableStashBuffer: false, // 如果您需要实时（最小延迟）来进行实时流播放，则设置为false
            autoCleanupSourceBuffer: true, // 对SourceBuffer进行自动清理
            stashInitialSize: 128, // 减少首帧显示等待时长
          }
        );
        console.log("this.flvPlayer", this.flvPlayer)
        /* const JSONStringify = (option) => {
          return JSON.stringify(option,
            (key, val) => {
              // 处理函数丢失问题
              if (typeof val === 'function') {
                return `${val}`;
              }
              // 处理undefined丢失问题
              if (typeof val === 'undefined') {
                return 'undefined';
              }
              return val;
            },
            2
          )
        } */
        // console.log("----------flvPlayer--->", that.flvPlayer, JSONStringify(that.flvPlayer));
        // that.flvPlayer.destroy();
        // sessionStorage.setItem(that.videoId, JSONStringify(that.flvPlayer));
        // console.log(JSON.parse(sessionStorage.getItem(that.videoId)));
        /* this.flvPlayer.attachMediaElement(videoElement);
        this.flvPlayer.load(); 
        this.flvPlayer.play();*/
      }
    },
  },
  watch: {
    httpFlv(newSrc) {
      console.log("流链接-->", newSrc, this.videoId);
      this.playSrc = newSrc;
      this.flv(newSrc);
    },
    /* flvData(data) {
      if (!data.mediaDataSource.playFlag) {
        // console.log("------错误了，需要执行-----", this.videoId, this.playSrc);
        // this.$emit("errVideo", this.videoId, this.playSrc)
      }
      // this.$emit("errVideo", data.mediaDataSource.videoId, data.mediaDataSource.url)
    }, */
  },
  mounted() {
    let that = this;
    // this.$loading()
    // 监听播放开始
    let videoElement = document.getElementById(this.videoId);
    if (videoElement) {
      videoElement.addEventListener("click", function (e) {
        // console.log("开始播放");
        // 阻止video点击事件
        e.preventDefault();
        // videoElement.play();
      });
    }
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.videoFlv {
  width: 100%;
  height: 100%;
}
</style>
