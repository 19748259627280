var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "videoFlv",
      attrs: {
        "element-loading-text": "加载中,请稍后...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.6)",
      },
    },
    [
      _vm._t("default"),
      _c("video", {
        staticStyle: { width: "100%", height: "100%", "object-fit": "fill" },
        attrs: { id: _vm.videoId, disablePictureInPicture: true, controls: "" },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }