/** 1+2 */
<template>
  <div class="split-screen-icon__four" :style="`width:${width}px;height:${height}px;`">
    <div class="left"></div>
    <div class="right">
      <div class="right-top"></div>
      <div class="right-bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  }
};
</script>

<style scoped lang="stylus">
.split-screen-icon__four {
    border: 1px solid #ffffff;
    display:flex;
    flex-direction:row;
    flex:2;
    box-sizing: border-box;
    .left {
        flex:1;
        // background:red;
        border-right:1px solid #ffffff;
    }
    .right {
        flex:1;
        display:flex;
        flex-direction:column;
        .right-top {
           flex:0.5;
        //    background:green;
           border-bottom:1px solid #ffffff;
        }
        .right-bottom {
           flex:0.5;
        //    background:orange;
        }
    }
}
</style>
