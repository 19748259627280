var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout__three" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        {
          staticClass: "list1",
          class: { three__active: _vm.activeIndex == 1 },
          on: {
            click: function ($event) {
              return _vm.handleClick(1)
            },
          },
        },
        [
          _vm.isShowVideo1
            ? _c("video-flv", {
                ref: "flvPlayer1",
                staticStyle: { "box-sizing": "border-box" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl1,
                  videoId: "videoId1",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "list2",
          class: { three__active: _vm.activeIndex == 2 },
          on: {
            click: function ($event) {
              return _vm.handleClick(2)
            },
          },
        },
        [
          _vm.isShowVideo2
            ? _c("video-flv", {
                ref: "flvPlayer2",
                staticStyle: { "box-sizing": "border-box" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl2,
                  videoId: "videoId2",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "list3",
          class: { three__active: _vm.activeIndex == 3 },
          on: {
            click: function ($event) {
              return _vm.handleClick(3)
            },
          },
        },
        [
          _vm.isShowVideo3
            ? _c("video-flv", {
                ref: "flvPlayer3",
                staticStyle: { "box-sizing": "border-box" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl3,
                  videoId: "videoId3",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "center" }, [
      _c(
        "div",
        {
          staticClass: "list4",
          class: { three__active: _vm.activeIndex == 4 },
          on: {
            click: function ($event) {
              return _vm.handleClick(4)
            },
          },
        },
        [
          _vm.isShowVideo4
            ? _c("video-flv", {
                ref: "flvPlayer4",
                staticStyle: { "box-sizing": "border-box" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl4,
                  videoId: "videoId4",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "list5",
          class: { three__active: _vm.activeIndex == 5 },
          on: {
            click: function ($event) {
              return _vm.handleClick(5)
            },
          },
        },
        [
          _vm.isShowVideo5
            ? _c("video-flv", {
                ref: "flvPlayer5",
                staticStyle: { "box-sizing": "border-box" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl5,
                  videoId: "videoId5",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "list6",
          class: { three__active: _vm.activeIndex == 6 },
          on: {
            click: function ($event) {
              return _vm.handleClick(6)
            },
          },
        },
        [
          _vm.isShowVideo6
            ? _c("video-flv", {
                ref: "flvPlayer6",
                staticStyle: { "box-sizing": "border-box" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl6,
                  videoId: "videoId6",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        {
          staticClass: "list7",
          class: { three__active: _vm.activeIndex == 7 },
          on: {
            click: function ($event) {
              return _vm.handleClick(7)
            },
          },
        },
        [
          _vm.isShowVideo7
            ? _c("video-flv", {
                ref: "flvPlayer7",
                staticStyle: { "box-sizing": "border-box" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl7,
                  videoId: "videoId7",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "list8",
          class: { three__active: _vm.activeIndex == 8 },
          on: {
            click: function ($event) {
              return _vm.handleClick(8)
            },
          },
        },
        [
          _vm.isShowVideo8
            ? _c("video-flv", {
                ref: "flvPlayer8",
                staticStyle: { "box-sizing": "border-box" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl8,
                  videoId: "videoId8",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "list9",
          class: { three__active: _vm.activeIndex == 9 },
          on: {
            click: function ($event) {
              return _vm.handleClick(9)
            },
          },
        },
        [
          _vm.isShowVideo9
            ? _c("video-flv", {
                ref: "flvPlayer9",
                staticStyle: { flex: "1" },
                attrs: {
                  videoType: _vm.videoType,
                  httpFlv: _vm.httpFlvUrl9,
                  videoId: "videoId9",
                },
                on: { errVideo: _vm.errVideo },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }