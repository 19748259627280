/** 3+4 */
<template>
  <div class="split-screen-icon__six" :style="`width:${width}px;height:${height}px;`">
    <div class="top">
      <div class="left"></div>
      <div class="right"></div>
    </div>
    <div class="bottom">
      <div class="left"></div>
      <div class="right">
        <div class="top">
          <div class="top-left"></div>
          <div class="top-right"></div>
        </div>
        <div class="bottom">
          <div class="bottom-left"></div>
          <div class="bottom-right"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  }
};
</script>

<style scoped lang="stylus">
.split-screen-icon__six {
    border: 1px solid #ffffff;
    display:flex;
    flex-direction:column;
    box-sizing: border-box;
    flex:8;
    .top {
      box-sizing: border-box;
      flex:4;
      display:flex;
      flex-direction:row;
      .left {
        flex:2;
        // background:red;
        border-right:1px solid #ffffff;
        border-bottom:1px solid #ffffff;
        box-sizing: border-box;
      }
      .right {
        flex:2;
        // background:blue;
                // border-right:1px solid #ffffff;
        border-bottom:1px solid #ffffff;
        box-sizing: border-box;
      }
    }
    .bottom {
        box-sizing: border-box;
        flex:4;
        display:flex;
        flex-direction:row;
       .left {
          flex:2;
        //   background:orange;
          border-right:1px solid #ffffff;
          box-sizing: border-box;
        // border-bottom:1px solid #ffffff;
       }
       .right {
           box-sizing: border-box;
           flex:2;
           display:flex;
           flex-direction:column;
        //    flex-wrap:wrap;
           .top {
              box-sizing: border-box;
              flex:1;
              display:flex;
              flex-direction:row;
              .top-left {
                flex:0.5;
                // background:green;
                border-right:1px solid #ffffff;
                border-bottom:1px solid #ffffff;
                box-sizing: border-box;
              }
              .top-right {
                flex:0.5;
                // background:#FFCCCC;
                border-bottom:1px solid #ffffff;
                box-sizing: border-box;
              }
           }
           .bottom {
              box-sizing: border-box;
              flex:1;
              display:flex;
              flex-direction:row;
              .bottom-left {
                box-sizing: border-box;
                flex:0.5;
                // background:#CCCCFF;
                border-right:1px solid #ffffff;
              }
              .bottom-right {
                box-sizing: border-box;
                flex:0.5;
                // background:#666633;
              }
           }
       }
    }
}
</style>
