var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout__one" }, [
    _c(
      "div",
      {
        staticClass: "one",
        class: { one__active: _vm.activeIndex == 1 },
        on: {
          click: function ($event) {
            return _vm.handleClick(1)
          },
        },
      },
      [
        _vm.isShowVideo
          ? _c("video-flv", {
              ref: "flvPlayer",
              attrs: { videoType: _vm.videoType, httpFlv: _vm.httpFlvUrl },
              on: { errVideo: _vm.errVideo },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }