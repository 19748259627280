/** 2+6 */
<template>
  <div class="split-screen-icon__seven" :style="`width:${width}px;height:${height}px;`">
    <div class="left">
      <div class="top"></div>
      <div class="bottom"></div>
    </div>
    <div class="center">
      <div class="top"></div>
      <div class="center"></div>
      <div class="bottom"></div>
    </div>
    <div class="right">
      <div class="top"></div>
      <div class="center"></div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  }
};
</script>

<style scoped lang="stylus">
.split-screen-icon__seven {
    border: 1px solid #ffffff;
    display:flex;
    flex-direction:row;
    box-sizing: border-box;
    flex:9;
    .left {
        box-sizing: border-box;
        flex:3;
        display:flex;
        flex-direction:column;
        .top {
          box-sizing: border-box;
          flex:1.5;
        //   background:green;
          border-right:1px solid #ffffff;
          border-bottom:1px solid #ffffff;
        }
        .bottom {
          box-sizing: border-box;
          flex:1.5;
        //   background:red;
          border-right:1px solid #ffffff;
        }
    }
    .center {
        flex:3;
        display:flex;
        flex-direction:column;
        box-sizing: border-box;
        .top {
          flex:1;
          box-sizing: border-box;
        //   background:orange;
          border-right:1px solid #ffffff;
          border-bottom:1px solid #ffffff;
        }
        .center {
          flex:1;
          box-sizing: border-box;
        //   background:blue;
          border-right:1px solid #ffffff;
          border-bottom:1px solid #ffffff;
        }
        .bottom {
          box-sizing: border-box;
          flex:1;
        //   background:yellow;
          border-right:1px solid #ffffff;
        }

    }
    .right {
        box-sizing: border-box;
        flex:3;
        display:flex;
        flex-direction:column;
        .top {
          flex:1;
          box-sizing: border-box;
        //   background:#666699;
          border-bottom:1px solid #ffffff;
        }
        .center {
          flex:1;
          box-sizing: border-box;
        //   background:#66CCCC;
          border-bottom:1px solid #ffffff;
        }
        .bottom {
          flex:1;
          box-sizing: border-box;
        //   background:#99CC33;
        }
    }
}
</style>
