/** 1*1 */
<template>
  <div class="split-screen-icon__one" :style="`width:${width}px;height:${height}px;`"></div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  },
};
</script>

<style scoped lang="stylus">
.split-screen-icon__one {
    border: 1px solid #ffffff;
    box-sizing: border-box;
}
</style>
