/** 2*2 */
<template>
  <div class="split-screen-icon__two" :style="`width:${width}px;height:${height}px;`">
    <div class="left-top"></div>
    <div class="right-top"></div>
    <div class="left-bottom"></div>
    <div class="right-bottom"></div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  }
};
</script>

<style scoped lang="stylus">
.split-screen-icon__two {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    div {
        width:50%;
        height:50%;
    }
    .left-top{
       border-bottom: 1px solid #ffffff;
       border-right: 1px solid #ffffff;
       box-sizing: border-box;
    }
    .right-top{
        border-bottom: 1px solid #ffffff;
        box-sizing: border-box;
    }
    .left-bottom{
       border-right: 1px solid #ffffff;
       box-sizing: border-box;
    }
    .right-bottom{
       box-sizing: border-box;
    }
}
</style>
