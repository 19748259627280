/** 1+2 */
<template>
  <div class="layout__four">
    <div class="left" :class="{ four__active: activeIndex == 1 }" @click="handleClick(1)">
      <video-flv
      :videoType="videoType"
        style="width:100%;height:100%;"
        :httpFlv="httpFlvUrl1"
        ref="flvPlayer1"
        videoId="videoId1"
        v-if="isShowVideo1"
        @errVideo="errVideo"
      >
      </video-flv>
    </div>
    <div class="right">
      <div class="right-top" :class="{ four__active: activeIndex == 2 }" @click="handleClick(2)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl2"
          ref="flvPlayer2"
          videoId="videoId2"
          v-if="isShowVideo2"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
      <div class="right-bottom" :class="{ four__active: activeIndex == 3 }" @click="handleClick(3)">
        <video-flv
        :videoType="videoType"
          style="width:100%;height:100%;"
          :httpFlv="httpFlvUrl3"
          ref="flvPlayer3"
          videoId="videoId3"
          v-if="isShowVideo3"
          @errVideo="errVideo"
        >
        </video-flv>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Md5Handle from "js-md5";
import VideoFlv from "../player/videoFlv";
import { nvrMediaUrl2, username, secretKey} from "@/common/js/videoConfig";
export default {
  components: {
    VideoFlv
  },
  props: {
    videoType: {
      type: Number,
      default: 1
    },
    layoutData4: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    }
  },
  data() {
    return {
      activeIndex: 1,
      isShowVideo1: false,
      isShowVideo2: false,
      isShowVideo3: false,
      httpFlvUrl1: "",
      httpFlvUrl2: "",
      httpFlvUrl3: ""
    };
  },
  watch: {
    videoType(val) {
      this.allFlvDestroy()
    },
    layoutData4: {
      handler(val) {
        console.log(val);
        let data_ = JSON.parse(JSON.stringify(val));
        if (JSON.stringify(data_) == "{}") {
          this.allFlvDestroy();
        } else {
          this.createFlv(data_);
        }
      },
      deep: true
    }
  },
  methods: {
    errVideo(item,httpFlv){
      console.log('出错了',item,)
      console.log('出错了',httpFlv,)
      if(item == 'videoId1'){
        this.isShowVideo1 = false;
      }else if(item == 'videoId2'){
        this.isShowVideo2 = false;
      }else if(item == 'videoId3'){
        this.isShowVideo3 = false;
      }
      this.getVideoUrl(httpFlv,item)
    },
    getVideoUrl(httpFlv,item){
        let params = {
          "userName": username,
          "stream_web": this.GetRequest(httpFlv).stream
        }
      let authentication = Md5Handle(username + secretKey);
      this.$axios({
        method: "post",
        url: nvrMediaUrl2,
        data: params,
        timeout: 50000,
        headers: {
          "content-type": "application/json",
          authentication: authentication, // authentication
        },
      }).then((res) => {
        console.log(res)
        if(item == 'videoId1'){
          this.isShowVideo1 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer1.flv(res.httpUrl)
          })
        }else if(item == 'videoId2'){
          this.isShowVideo2 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer2.flv(res.httpUrl)
          })
        }else if(item == 'videoId3'){
          this.isShowVideo3 = true;
          this.$nextTick(()=>{
            this.$refs.flvPlayer3.flv(res.httpUrl)
          })
        }
      })
    },
    GetRequest(urlStr) {
       if (typeof urlStr == "undefined") {
           var url = decodeURI(location.search); //获取url中"?"符后的字符串
       } else {
           var url = "?" + urlStr.split("?")[1];
       }
       let theRequest = new Object();
       if (url.indexOf("?") != -1) {
           let str = url.substr(1);
           let strs = str.split("&");
           for (var i = 0; i < strs.length; i++) {
               theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
           }
       }
       return theRequest;
    },
    activeHasVideo() {
      return this[`isShowVideo${this.activeIndex}`];
    },
    createFlv(data) {
      for (let i = 1; i < 4; i++) {
        if (data[`httpFlvUrl${i}`] && data[`httpFlvUrl${i}`] != "") {
          //   this.isShowVideo = true;
          if (!this[`isShowVideo${i}`]) {
            console.log(this[`isShowVideo${i}`], `httpFlvUrl${i}`);
            this[`isShowVideo${i}`] = true;
            this.$nextTick(() => {
              this[`httpFlvUrl${i}`] = data[`httpFlvUrl${i}`];
            });
          } else if (this.activeIndex == i) {
            this[`httpFlvUrl${i}`] = "";
            if (this.$refs[`flvPlayer${i}`]) {
              this.$refs[`flvPlayer${i}`].flv_destroy();
            }
            this.$nextTick(() => {
              this[`httpFlvUrl${i}`] = data[`httpFlvUrl${i}`];
            });
          }
        } else {
          if (this[`isShowVideo${i}`]) {
            this[`httpFlvUrl${i}`] = "";
            if (this.$refs[`flvPlayer${i}`]) {
              this.$refs[`flvPlayer${i}`].flv_destroy();
            }
            this[`isShowVideo${i}`] = false;
          }
        }
      }
    },
    allFlvDestroy() {
      for (let i = 1; i < 4; i++) {
        this[`httpFlvUrl${i}`] = "";
        if (this.$refs[`flvPlayer${i}`]) {
          this.$refs[`flvPlayer${i}`].flv_destroy();
        }
        this[`isShowVideo${i}`] = false;
      }
    },
    handleClick(index) {
      if (this.activeIndex == index) {
        return;
        this.activeIndex = 0;
      } else {
        this.activeIndex = index;
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.layout__four {
    // border: 1px solid #ffffff;
    display:flex;
    flex-direction:row;
    flex:2;
    box-sizing: border-box;
    height:100%;
    padding:4px 4px 4px 4px;
    &:hover{
      cursor pointer;
    }
    .four__active{
      border: 1px solid #FF9900;
      transition: all linear 0.3s;
      box-sizing: border-box;
    }
    .left {
        flex:1;
        box-sizing: border-box;
        // background:red;
        // border-right:1px solid #ffffff;
        background: hsla(0,0%,100%,.1);
        margin-right: 5px;
    }
    .right {
        flex:1;
        display:flex;
        flex-direction:column;
        box-sizing: border-box;
        .right-top {
           box-sizing: border-box;
           flex:0.5;
           background: hsla(0,0%,100%,.1);
           margin-bottom: 5px;
           height: 50%;
        //    background:green;
          //  border-bottom:1px solid #ffffff;
        }
        .right-bottom {
           box-sizing: border-box;
           flex:0.5;
           background: hsla(0,0%,100%,.1);
           height:49%;
        //    background:orange;
        }
    }
}
</style>
