var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "split-screen-icon__two",
      style: `width:${_vm.width}px;height:${_vm.height}px;`,
    },
    [
      _c("div", { staticClass: "left-top" }),
      _c("div", { staticClass: "right-top" }),
      _c("div", { staticClass: "left-bottom" }),
      _c("div", { staticClass: "right-bottom" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }