<template>
  <div class="layout-index__wrapper">
    <layout-one v-if="layout == '1*1'" v-bind="$attrs" :ref="layout" :layoutData1="layoutData1"></layout-one>
    <layout-two v-if="layout == '2*2'" v-bind="$attrs" :ref="layout" :layoutData2="layoutData2"></layout-two>
    <layout-three v-if="layout == '3*3'" v-bind="$attrs" :ref="layout" :layoutData3="layoutData3"></layout-three>
    <layout-four v-if="layout == '1+2'" v-bind="$attrs" :ref="layout" :layoutData4="layoutData4"></layout-four>
    <layout-five v-if="layout == '1+5'" v-bind="$attrs" :ref="layout" :layoutData5="layoutData5"></layout-five>
    <layout-six v-if="layout == '3+4'" v-bind="$attrs" :ref="layout" :layoutData6="layoutData6"></layout-six>
    <layout-seven v-if="layout == '2+6'" v-bind="$attrs" :ref="layout" :layoutData7="layoutData7"></layout-seven>
  </div>
</template>

<script>
/* eslint-disable */
import LayoutOne from "./one";
import LayoutTwo from "./two";
import LayoutThree from "./three";
import LayoutFour from "./four";
import LayoutFive from "./five";
import LayoutSix from "./six";
import LayoutSeven from "./seven";
export default {
  props: {
    layoutData1: {
      type: Object,
      default: () => {}
    },
    layoutData2: {
      type: Object,
      default: () => {}
    },
    layoutData3: {
      type: Object,
      default: () => {}
    },
    layoutData4: {
      type: Object,
      default: () => {}
    },
    layoutData5: {
      type: Object,
      default: () => {}
    },
    layoutData6: {
      type: Object,
      default: () => {}
    },
    layoutData7: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: String,
      default: "1*1"
    }
  },
  components: {
    LayoutOne,
    LayoutTwo,
    LayoutThree,
    LayoutFour,
    LayoutFive,
    LayoutSix,
    LayoutSeven
  },
  watch: {
    layout: {
      handler(val) {
        console.log(val);
      }
    }
  },
  methods: {
    getActiveIndex() {
      return this.$refs[this.layout].activeIndex;
    },
    activeHasVideo(){
      return this.$refs[this.layout].activeHasVideo()
    },
    allFlvDestroyVideo() {
      this.$refs[this.layout].allFlvDestroy()
    },
    isActive() {
      if (this.$refs[this.layout].activeIndex > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.layout-index__wrapper {
  height:100%;
  width:100%;
}
</style>
